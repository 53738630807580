<template>
  <v-responsive :aspect-ratio="16/9" max-width="960" class="wrapper">
    <iframe ref="frame" :src="src" class="iframe" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"></iframe>
    <div class="action full" v-show="!isFullscreen" @click="changeFullscreen(true)">全屏</div>
  </v-responsive>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: ''
    }
  },
  computed: {
    wrapperStyle () {
      if (this.isFullscreen) {
        return {
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%'
          // zIndex: 9999
        }
      }
      return {
        position: 'relative',
        width: '100%',
        height: '100%'
      }
    }
  },
  methods: {
    changeFullscreen (status) {
      const frame = this.$refs.frame
      this.requestFullscreen(frame)
    },
    requestFullscreen (el) {
      return (el.requestFullscreen || el.webkitRequestFullscreen || el.mozRequestFullScreen || el.msRequestFullscreen).call(el)
    }
  }
}
</script>

<style lang="scss" scoped>
.iframe {
  position: relative;
  width: 100%;
  height: 100%;
}
.wrapper {
  transition: all 0.2s;
  .action {
    // display: none;
    position: absolute;
    width: 50px;
    height: 50px;
    transition: all 0.2s;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 60px;
    background: rgba(0, 0, 0, 0.2);
    color: #ddd;
    backdrop-filter: blur(2px);
    &:hover {
      background: rgba(0, 0, 0, 0.3);
      opacity: 1;
      color: #fff;
    }
  }
  .action.full {
    right: 10px;
    bottom: 10px;
    border-radius: 50%;
    font-size: 18px;
  }
  .action.close {
    right: 10px;
    top: 10px;
    border-radius: 50%;
    transform: rotate(45deg);
  }
}
</style>
