<template>
  <v-row>
    <v-col md="5" cols="12">
      <v-btn
        elevation="0"
        :outlined="tips.disabled"
        :dark="!tips.disabled"
        :color="tips.color"
        block
        :disabled="tips.disabled"
        @click="onClick"
        large
      >
        <v-icon class="mr-2">{{ tips.icon }}</v-icon>
        <div class="text-caption">{{ tips.label }}</div>
      </v-btn>
      <!-- TODO: 考虑更自由的写法，兼容多种 type -->
      <div v-if="result">
        <v-bottom-sheet
          v-model="sheet"
          inset
        >
          <v-sheet
            class="text-center"
            height="250px"
          >
            <v-card
              class="mx-auto elevation-0"
            >
              <v-card-title>{{ result.resource.name }}</v-card-title>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>链接</v-list-item-title>
                  <v-list-item-subtitle>{{ result.resource.address }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>提取码</v-list-item-title>
                  <v-list-item-subtitle>{{ result.resource.code }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-sheet>
        </v-bottom-sheet>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { getArticleResource } from '@/apis/article'

const TIPS = {
  0: {
    icon: 'mdi-package-variant',
    color: 'grey',
    label: '本文暂无相关资源',
    disabled: true
  },
  1: {
    icon: 'mdi-download',
    color: '#536dfe',
    label: '点击扫码关注，查看百度云资源',
    disabled: false
  },
  2: {
    icon: 'mdi-link',
    color: 'light-blue',
    label: '点击打开链接',
    disabled: false
  }
}

export default {
  props: {
    resource: {
      type: Object,
      default () {
        return {}
      }
    },
    id: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      sheet: false,
      result: null
    }
  },
  computed: {
    type () {
      return this.resource.type || 0
    },
    tips () {
      return TIPS[this.type]
    }
  },
  methods: {
    onClick () {
      if (this.result) {
        this.sheet = true
        return
      }
      this.$auth((code, { setLoading, setClose }) => {
        setLoading(true)
        getArticleResource(this.id)({ code }).then(({ data }) => {
          this.onSuccess(data)
          setClose()
        }).catch(err => {
          this.$noti.error(err.message)
        }).finally(() => {
          setLoading(false)
        })
      })
    },
    onSuccess (data) {
      this.result = data
      this.sheet = true
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
