<template>
  <v-container class="container">
    <ribbon :opacity="0.8"></ribbon>
    <!-- <div class="sense">
      <Adsense
        style="width: 100%; min-width: 250px;"
        ins-style="display:inline-block;width:936px;height:180px"
        data-ad-client="ca-pub-9548306282555760"
        data-ad-slot="1534464986"
        data-full-width-responsive="yes"
        data-ad-format="auto"
        root-class="a-sense"
        is-new-ads-code="yes"
      >
      </Adsense>
    </div> -->
    <!-- 页面面包屑 -->
    <v-breadcrumbs :items="breadcrumbItems"></v-breadcrumbs>
    <v-fade-transition mode="out-in">
      <template v-if="isLoading">
        <v-row>
          <v-col>
            <v-skeleton-loader
              type="card-avatar, article"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </template>
      <template v-else-if="detail">
        <v-row>
          <v-col cols="12">
            <v-sheet elevation="1" class="pa-10 sheet rounded-lg mb-10">
              <!-- 日期 -->
              <div class="header mb-4">
                <v-chip
                  dark
                  color="white"
                  class="grey--text text--darken-4  mb-2 mr-2"
                  label
                >{{ formattedDate(detail.updateTime) }}</v-chip>
              </div>
              <!-- 标题 -->
              <div class="title mb-6 font-weight-light" :class="$vuetify.breakpoint.xs ? 'text-h5' : 'text-h2'">
                {{ detail.name }}
              </div>
              <!-- 描述 -->
              <div class="describe text-subtitle-1 font-weight-light font-italic mb-6 grey--text">
                {{ detail.describe }}
              </div>
              <!-- 标签 -->
              <div class="tags mb-8">
                <v-chip v-for="tag in detail.tagEntities"
                  :key="tag.id"
                  dark
                  :color="tag.color"
                  class="text-caption mb-2 mr-4"
                  outlined
                >{{ tag.name }}</v-chip>
              </div>
              <!-- 资源 -->
              <resource-button :resource="detail.resource" class="mb-2" :id="detail.id"></resource-button>
              <v-divider class="mb-6"></v-divider>
              <!-- 文章内容 -->
              <div class="mt-2">
                <!-- 视频 -->
                <div v-if="detail.videoAddress" class="video-wrapper mb-8 elevation-4">
                  <bili-video v-if="isBiliVideo(detail.videoAddress)" :link="detail.videoAddress"></bili-video>
                  <game-frame v-else :src="detail.videoAddress"></game-frame>
                </div>
                <!-- 正文 -->
                <m-d-view :content="detail.content"></m-d-view>
              </div>
              <div class="recommend-article-wrapper mt-10" v-if="recommendArticle">
                <v-divider></v-divider>
                <div class="title font-weight-light">
                  <span class="text-h6">推荐阅读</span>
                </div>
                <div class="recommend-article mt-10">
                  <article-card :article="recommendArticle"></article-card>
                </div>
              </div>
            </v-sheet>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <empty tips="文章不见了~"></empty>
      </template>
    </v-fade-transition>
  </v-container>
</template>

<script>
import dayjs from 'dayjs/esm'
import { getArticle } from 'apis/article'
import MDView from '@/components/markdown/MDView.vue'
import ArticleCard from '@/components/ArticleCard.vue'
import BiliVideo from 'components/BiliVideo.vue'
import GameFrame from 'components/GameFrame.vue'
import ResourceButton from 'components/ResourceButton.vue'
import Ribbon from 'components/Ribbon.vue'
import { setTitle } from '@/utils'
import { mapMutations } from 'vuex'

export default {
  components: {
    ArticleCard,
    MDView,
    BiliVideo,
    ResourceButton,
    Ribbon,
    GameFrame
  },
  data () {
    return {
      isLoading: false,
      detail: null
    }
  },
  computed: {
    id () {
      return this.$route.params.id
    },
    breadcrumbItems () {
      return [
        {
          text: '首页',
          disabled: false,
          to: '/'
        },
        {
          text: this.detail ? this.detail.name : '文章',
          disabled: true,
          to: '/article/1'
        }
      ]
    },
    recommendArticle () {
      return this.detail?.nextArticle
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler () {
        this.getArticleDetail()
      }
    }
  },
  methods: {
    ...mapMutations('app', ['updateArticleEditTime']),
    updateEditTime (article) {
      this.updateArticleEditTime({
        id: article.id,
        time: article.updateTime
      })
    },
    getArticleDetail () {
      this.isLoading = true
      getArticle(this.id)().then(({ data: detail }) => {
        this.detail = detail
        this.updateEditTime(detail)
        setTitle(detail.name)
      }).catch(err => {
        setTitle('找不到该文章')
        console.log(err)
        this.$noti.error(err.message)
      }).finally(() => {
        this.isLoading = false
      })
    },
    formattedDate (ms) {
      return dayjs(ms).locale('zh-cn').format('MMMM D, YYYY')
    },
    isBiliVideo (link) {
      return link.includes('player.bilibili.com')
    }
  }
}
</script>

<style lang="scss" scoped>
.sense {
  display: block;
  height: 180px;
  max-height: 180px;
  width: 100%;
}
.container {
  max-width: 960px;
  position: relative;
  z-index: 1;
}
// .title {
//   position: sticky;
//   top: 64px;
//   z-index: 1000;
// }
.sheet {
  box-shadow: 0 3px 11px 0 #e8eafc,0 3px 3px -2px hsla(0,0%,69.8%,.10196078431372549),0 1px 8px 0 hsla(0,0%,60.4%,.10196078431372549) !important;
}
.recommend-article-wrapper {
  position: relative;
  text-align: center;
  .title {
    // text-align: left;
    background: white;
    position: absolute;
    left: 50%;
    top: 0px;
    transform: translate3d(-50%, -50%, 0);
    span {
      color: #536dfe;
      letter-spacing: 4px !important;
      user-select: none;
    }
  }
  .recommend-article {
    text-align: left;
    display: inline-block;
  }
}
</style>
