<template>
  <canvas ref="canvas" class="canvas" :style="style"></canvas>
</template>

<script>

export default {
  props: {
    opacity: {
      type: Number,
      default: 0.6
    },
    zIndex: {
      type: Number,
      default: -1
    },
    size: {
      type: Number,
      default: 90
    }
  },
  data () {
    return {
      r: 0,
      q: [],
      t: 0
    }
  },
  computed: {
    style () {
      return {
        opacity: this.opacity,
        zIndex: this.zIndex
      }
    }
  },
  mounted () {
    const canvas = this.$refs.canvas
    const ctx = canvas.getContext('2d')
    const dpr = window.devicePixelRatio || 1
    const width = window.innerWidth
    const height = window.innerHeight
    canvas.width = width * dpr
    canvas.height = height * dpr
    ctx.scale(dpr, dpr)
    ctx.globalAlpha = this.opacity
    this.ctx = ctx
    this.redraw()
    document.onclick = this.redraw
  },
  methods: {
    line (p) {
      const height = window.innerHeight
      const size = this.size
      this.t = p + (Math.random() * 2 - 1.1) * size
      return (this.t > height || this.t < 0) ? this.line(p) : this.t
    },
    draw (i, j) {
      if (!this.ctx) {
        return
      }
      const ctx = this.ctx
      const size = this.size
      const pi = Math.PI
      ctx.beginPath()
      ctx.moveTo(i.x, i.y)
      ctx.lineTo(j.x, j.y)
      const k = j.x + (Math.random() * 2 - 0.25) * size
      const n = this.line(j.y)
      ctx.lineTo(k, n)
      ctx.closePath()
      this.r -= pi / -50
      ctx.fillStyle = '#' + (Math.cos(this.r) * 127 + 128 << 16 | Math.cos(this.r + pi / 3) * 127 + 128 << 8 | Math.cos(this.r + pi / 3 * 2) * 127 + 128).toString(16)
      ctx.fill()
      this.q[0] = this.q[1]
      this.q[1] = { x: k, y: n }
    },
    redraw () {
      if (!this.ctx) {
        return
      }
      const ctx = this.ctx
      const width = window.innerWidth
      const height = window.innerHeight
      ctx.clearRect(0, 0, width, height)
      const size = this.size
      this.q = [{ x: 0, y: height * 0.7 + size }, { x: 0, y: height * 0.7 - size }]
      while (this.q[1].x < width + size) {
        this.draw(this.q[0], this.q[1])
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
</style>
