<template>
  <div id="md-content" v-html="renderedHTML"></div>
</template>

<script>
import hljs from 'highlight.js'
import { marked } from 'marked/lib/marked.esm'
// import 'highlight.js/styles/atom-one-dark.css'
import 'highlight.js/styles/atom-one-light.css'

const renderer = new marked.Renderer()

function sanitize (str) {
  return str.replace(/&<"/g, function (m) {
    if (m === '&') return '&amp;'
    if (m === '<') return '&lt;'
    return '&quot;'
  })
}

renderer.image = function (src, title, alt) {
  // const exec = /=\s*(\d*)\s*x\s*(\d*)\s*$/.exec(title)
  const res = '<div style="width: fit-content; font-size: 0; margin: auto;"><img src="' + sanitize(src) + '" alt="' + sanitize(alt)
  // if (exec && exec[1]) res += '" height="' + exec[1]
  // if (exec && exec[2]) res += '" width="' + exec[2]
  return res + '" style="display: inline-block; width: 100%; max-width: 600px"></div>'
}

renderer.strong = function (text) {
  return `<strong class="text-body-1 font-weight-bold" style="display: inline-block">${sanitize(text)}</strong>`
}

renderer.em = function (text) {
  return `<em class="text-body-1 font-italic">${sanitize(text)}</em>`
}

renderer.paragraph = function (text) {
  return `<p class="text-body-2 mb-4">${sanitize(text)}</p>`
}

renderer.heading = function (text, level, raw, slugger) {
  level += level <= 3 ? 3 : 0
  const h = 'h' + level
  const anchor = this.options.headerPrefix + slugger.slug(raw)
  return `<${h} class="text-h${level} mb-2 mt-8" id="${anchor}">${sanitize(text)}</${h}>`
}

renderer.list = function (body, ordered) {
  const type = ordered ? 'ol' : 'ul'
  return `<${type} class="mt-2 mb-4">${body}</${type}>`
}

renderer.listitem = function (text) {
  return `<li class="text-body-2 mb-2">${sanitize(text)}</li>`
}

renderer.hr = function () {
  return '<hr style="border-color:rgba(0,0,0,.12) !important;border:solid;border-width:thin 0 0 0;max-width:100%;height:0;flex: 1 1 0px;"></hr>'
}

export default {
  props: {
    content: {
      type: String,
      default: ''
    }
  },
  computed: {
    renderedHTML () {
      marked.setOptions({
        renderer: renderer,
        gfm: true
      })
      return marked.parse(this.content)
    }
  },
  mounted () {
    hljs.highlightAll()
  }
}
</script>

<style lang="scss" scoped>

</style>
