<template>
  <v-responsive :aspect-ratio="16/9" max-width="960">
    <iframe class="player" :src="link" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"> </iframe>
  </v-responsive>
</template>

<script>

export default {
  props: {
    link: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.player {
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
